import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import Link from "next/link";
import Arrow from "@/components/icons/Arrow";

const dynamicVariants = {
  default: {
    nsc: "bg-nsc-yellow text-nsc-dark-blue hover:bg-nsc-yellow-hover",
    jsc: "bg-jsc-yellow text-black hover:bg-jsc-yellow-hover",
  },
  secondary: {
    nsc: "bg-nsc-dark-blue text-white hover:bg-nsc-dark-blue-hover",
    jsc: "bg-jsc-sky-blue text-white hover:bg-jsc-sky-blue-hover",
  },
  ghost: {
    nsc: "bg-transparent text-nsc-dark-blue",
    jsc: "bg-transparent text-black",
  },
};

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const buttonVariants = cva(
  "button",
  {
    variants: {
      variant: {
        default: dynamicVariants.default[websiteId],
        secondary: dynamicVariants.secondary[websiteId],
        ghost: dynamicVariants.ghost[websiteId],
        link: "link p-0",
        "nsc-mint": "bg-nsc-mint text-nsc-dark-blue",
        "nsc-orange": "bg-nsc-orange text-nsc-dark-blue",
        "nsc-ice-blue": "bg-nsc-ice-blue text-nsc-dark-blue",
        "nsc-europe-blue": "bg-nsc-europe-blue text-white",
        "jsc-white-black": "bg-white text-black",
        "jsc-sky-blue-white": "bg-jsc-sky-blue text-white",
      },
      size: {
        default: "h-[40px] px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-12 px-8",
        icon: "h-10 w-10",
        round: "rounded-full h-[40px] w-[40px]",
        link: "p-0 w-fit",
      },
      fullWidth: {
        true: "w-full",
        false: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      fullWidth: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, fullWidth, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

export interface ButtonLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {
  href: string;
  arrowColor?: string;
}

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      className,
      variant = "link",
      size = "link",
      fullWidth,
      href,
      children,
      arrowColor = "#171C60",
      target = "_self",
      ...props
    },
    ref
  ) => {
    return (
      <Link
        href={href}
        className={cn(buttonVariants({ variant, size, fullWidth, className }), "group flex")}
        ref={ref}
        target={target}
        {...props}
      >
        <>
          <span className="text-wrap flex items-center gap-4 leading-6">{children}</span>
          {variant === "link" && (
            <Arrow color={arrowColor} className="ml-3 min-w-fit transition-transform group-hover:translate-x-1" />
          )}
        </>
      </Link>
    );
  }
);

ButtonLink.displayName = "ButtonLink";

export { Button, ButtonLink, buttonVariants };
