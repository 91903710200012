"use client";

import Link from "next/link";
import { Facebook, Linkedin } from "lucide-react";
import Image from "next/image";

import X from "@/components/icons/X";
import Instagram from "@/components/icons/Instagram";
import Youtube from "@/components/icons/Youtube";
import { ButtonLink } from "@/components/ui/button";
import { cn, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import { useStore } from "@/store";

const FooterJSC = ({ settings }: any) => {
  const setCookiesPreferences = useStore((state) => state.setCookiesPreferences);

  if (!settings?.data?.attributes) return null;

  const menuData = settings.data.attributes.websiteFooter;
  const firstMenu = menuData?.menus[0];
  const secondMenu = menuData?.menus[1];
  const thirdMenu = menuData?.menus[2] || {};

  const socials = settings.data.attributes.Socials;

  const renderSocialIcon = (icon: string) => {
    switch (icon) {
      case "Instagram":
        return <Instagram color={"black"} className={`h-4 w-4`} />;
      case "Youtube":
        return <Youtube color={"black"} className={`h-4 w-4`} />;
      case "Twitter":
        return <X color={"black"} className={`h-4 w-4`} />;
      case "Facebook":
        return <Facebook color={"black"} className={`h-4 w-4 fill-black stroke-none`} />;
      case "LinkedIn":
        return <Linkedin color={"black"} className={`h-4 w-4 fill-black stroke-none`} />;
      default:
        return null;
    }
  };

  return (
    <footer className={cn("relative z-[100] pb-[72px] pt-[72px] md:pb-[60px] md:pt-[100px]", "bg-black text-white")}>
      <div className="grid-container container">
        <div className="col-span-12 mb-[62px] md:col-span-2 md:mb-0">
          <div
            className={cn(
              "relative",
              "left-[30px] top-[0px] bg-white h-[45px] w-[59px] -rotate-[10deg] scale-[1.4] rounded-md md:left-[35px] md:top-[0px] md:scale-[2.1] md:shadow-lg"
            )}
            color="white"
          >
            <Image
              src={strapiMediaUrl(menuData.logo)}
              alt={strapiMediaAlt(menuData.logo)}
              priority
              fill={true}
              className={cn(
                "rotate-[6deg]"
              )}
            />
          </div>
        </div>

        <div className="col-span-12 mb-8 md:col-span-3 md:mb-0">
          <nav>
            <ul className="flex flex-col gap-4">
              {firstMenu?.items.map((item: any, index: number) => (
                <li key={item.id} className={`leading-0 font-regular text-base underline md:block`}>
                  <Link href={item.url}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="col-span-12 md:col-span-3">
          <div className="flex flex-col justify-between gap-y-6 md:gap-14 md:gap-y-14">
            <nav>
              <ul className="mb-4 flex flex-col gap-4 md:mb-0">
                {secondMenu?.items.map((item: any, index: number) => {
                  return (
                    <li key={item.id} className={`leading-0 font-regular text-base underline`}>
                      <Link href={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>

        <div className="col-span-12 mt-[48px] md:col-span-3 md:mt-0">
          <div className="flex flex-col">
            <ButtonLink href="https://partijnieuwsociaalcontract.nl/lid-worden" variant="default" size="default"
                        className="mb-2 w-full underline" fullWidth target="_blank">
              Meedoen met JSC
            </ButtonLink>

            <ButtonLink
              href="https://mailing.partijnieuwsociaalcontract.nl/subscribeform.php?form=3&token=59cf8a4e3c8e2111d3e39c4ba765fc2e1731069892"
              variant="jsc-white-black"
              size="default"
              className="mb-11 w-full underline"
              fullWidth
            >
              Aanmelden voor onze nieuwsbrief
            </ButtonLink>

            <div className={`flex items-center gap-1`}>
              {socials.map((social: any) => {
                return (
                  <Link
                    key={social.id}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`rounded-full bg-white p-3`}
                  >
                    <span className="sr-only">{social.title}</span>
                    {renderSocialIcon(social.socialNetwork)}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="grid-container container mt-12 md:mt-24">
        <div className="col-span-12 flex flex-col gap-6 md:col-span-3 md:col-start-3">
          <Link href="/privacy-statement" className="font-regular underline">
            Privacy Statement
          </Link>
          <Link href="/anbi" className="font-regular underline">
            ANBI
          </Link>
        </div>
        <div className="col-span-12 flex flex-col gap-6 md:col-span-3">
          <Link href="#" className="font-regular underline">
            Zoeken
          </Link>
          <Link href="/mijn-account/inloggen" className="font-regular underline">
            Inloggen
          </Link>
        </div>
        <div className="col-span-12 md:col-span-3">
          <p
            className="font-regular whitespace-pre text-pretty leading-6"
            dangerouslySetInnerHTML={{ __html: menuData?.information }}
          />
        </div>
      </div>
    </footer>
  );
};

export default FooterJSC;
