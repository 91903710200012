"use client";


import CookieBannerNSC from "@/components/layout/nsc/Cookies";
import CookieBannerJSC from "@/components/layout/jsc/Cookies";

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const CookiesWrapper = ({ settings }: any) => {
  return websiteId === "nsc" ? <CookieBannerNSC /> : <CookieBannerJSC />;
};

export default CookiesWrapper;
