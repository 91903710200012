"use client";

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { useStore } from "@/store";
import Link from "next/link";

interface Preferences {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
}

const CookieBannerNSC: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const { cookiesPreferences, setCookiesPreferences } = useStore();
  const [preferences, setPreferences] = useState<Preferences>({
    necessary: true,
    preferences: true,
    statistics: true,
    marketing: false,
  });

  useEffect(() => {
    const consentCookie = Cookies.get("CookieConsent");
    if (consentCookie) {
      const consent = parseCookieValue(consentCookie);
      if (consent) {
        setPreferences(consent);
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    } else {
      setShowBanner(true);
    }
  }, []);

  const parseCookieValue = (cookieValue: string): Preferences | null => {
    try {
      const trimmed = cookieValue.trim();
      if (trimmed.startsWith("{") && trimmed.endsWith("}")) {
        const content = trimmed.slice(1, -1);
        const pairs = content.split(",");
        const result: any = {};
        pairs.forEach((pair) => {
          const [key, value] = pair.split(":");
          result[key] = value === "true";
        });
        return {
          necessary: true,
          preferences: result.preferences === "true",
          statistics: result.statistics === "true",
          marketing: result.marketing === "true",
        };
      }
    } catch (e) {
      console.error("Fout bij het parsen van CookieConsent cookie", e);
    }
    return null;
  };

  const handleToggle = (category: keyof Preferences) => {
    if (category === "necessary" || category === "marketing") {
      return;
    }
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [category]: !prevPreferences[category],
    }));
  };

  const handleAcceptAll = () => {
    setPreferences({
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: false,
    });
    const cookieValue = `{necessary:true,preferences:true,statistics:true,marketing:false}`;
    Cookies.set("CookieConsent", cookieValue, { expires: 365 });
    setShowBanner(false);
  };

  const handleRejectAll = () => {
    setPreferences({
      necessary: true,
      preferences: false,
      statistics: false,
      marketing: false,
    });
    const cookieValue = `{necessary:true,preferences:false,statistics:false,marketing:false}`;
    Cookies.set("CookieConsent", cookieValue, { expires: 365 });
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    const cookieValue = `{necessary:true,preferences:${preferences.preferences},statistics:${preferences.statistics},marketing:false}`;
    Cookies.set("CookieConsent", cookieValue, { expires: 365 });
    setCookiesPreferences(false);
    setShowBanner(false);
  };

  return (
    <>
      {(showBanner || cookiesPreferences) && (
        <div
          className="fixed bottom-0 right-0 z-[101] w-full bg-white p-4 shadow-xl md:w-1/2 md:min-w-[500px] md:p-8 lg:p-8">
          <h4 className="font-calluna text-[22px] font-bold text-nsc-dark-blue">Cookie melding</h4>
          <p className="mt-4 font-calluna font-light text-dark-blue">
            Onze website maakt gebruik van cookies. Door op `Toestaan` te klikken gaat u akkoord met ons{" "}
            <Link href="/privacy-statement">Privacy-reglement.</Link>
          </p>
          <div className="mt-8 flex w-full flex-wrap justify-between gap-2 md:flex-nowrap">
            <Button
              variant="link"
              className="order-1 w-[calc(50%-4px)] bg-transparent font-roboto !no-underline md:order-none md:w-full"
              onClick={handleRejectAll}
            >
              Alles afwijzen
            </Button>

            <Dialog
              open={cookiesPreferences}
              onOpenChange={(value: boolean) => {
                setCookiesPreferences(value);
              }}
            >
              <DialogTrigger asChild>
                <Button variant="link" className="mb-2 w-full md:mb-0">
                  Voorkeuren
                </Button>
              </DialogTrigger>
              <DialogContent className="max-h-[90vh] overflow-auto p-4 md:p-12">
                <DialogTitle className="sr-only">Cookies voorkeuren</DialogTitle>
                <div className="">
                  <div className="flex flex-col gap-6">
                    <h2 className="header-3">Privacyvoorkeuren.</h2>
                    <p>
                      Wanneer je websites bezoekt, kunnen ze gegevens in je browser opslaan of ophalen. Deze opslag is
                      vaak nodig voor de basisfunctionaliteit van de website. De opslag kan worden gebruikt voor analyse
                      en personalisatie, zoals het opslaan van je voorkeuren. Privacy is belangrijk voor ons, dus je
                      hebt de optie om bepaalde soorten opslag uit te schakelen die niet nodig zijn voor de basiswerking
                      van de website. Het blokkeren van categorieën kan invloed hebben op je ervaring op de site.
                    </p>
                    <div className="mb-4 flex gap-2">
                      <Button variant="secondary" onClick={handleRejectAll}>
                        Weiger alle cookies
                      </Button>
                      <Button onClick={handleAcceptAll}>Sta alle cookies toe</Button>
                    </div>
                    <h3 className="header-4">Beheer voorkeuren per categorie</h3>
                    <div className="border-gray relative flex flex-col gap-2 border-b pb-4">
                      <h4 className="header-5">Essentieel</h4>
                      <p>Deze items zijn nodig voor de basisfunctionaliteit van de website.</p>
                      <p className="absolute right-0 top-0 font-oswald uppercase text-nsc-yellow">Altijd Actief</p>
                    </div>
                    <div className="border-gray relative flex flex-col gap-2 border-b pb-4">
                      <h4 className="header-5">Marketing</h4>
                      <p>Wij gebruiken je data niet voor marketingdoeleinden en gebruiken dus geen marketingcookies.</p>
                      <Switch
                        className="absolute right-0 top-0"
                        id="marketing"
                        disabled
                        checked={preferences.marketing}
                        onCheckedChange={() => handleToggle("marketing")}
                      />
                    </div>
                    <div className="border-gray relative flex flex-col gap-2 border-b pb-4">
                      <h4 className="header-5">Personalisatie</h4>
                      <p>
                        Deze items stellen ons in staat om keuzes die je maakt te onthouden (zoals je gebruikersnaam,
                        taal of de regio waarin je je bevindt) en bieden verbeterde, meer persoonlijke functies.
                      </p>
                      <Switch
                        className="absolute right-0 top-0"
                        id="preferences"
                        checked={preferences.preferences}
                        onCheckedChange={() => handleToggle("preferences")}
                      />
                    </div>
                    <div className="border-gray relative flex flex-col gap-2 border-b pb-4">
                      <h4 className="header-5">Analyse</h4>
                      <p>
                        Deze items helpen ons te begrijpen hoe de website presteert, hoe bezoekers de site gebruiken en
                        of er technische problemen zijn. Dit type opslag verzamelt geen informatie die een bezoeker kan
                        identificeren.
                      </p>
                      <Switch
                        className="absolute right-0 top-0"
                        id="statistics"
                        checked={preferences.statistics}
                        onCheckedChange={() => handleToggle("statistics")}
                      />
                    </div>
                    <div>
                      <Button onClick={handleSavePreferences}>Voorkeuren opslaan</Button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            <Button className="order-1 w-[calc(50%-4px)] md:order-none md:w-full" onClick={handleAcceptAll}>
              Accepteren
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBannerNSC;
