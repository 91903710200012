"use client";

import HeaderNSC from "@/components/layout/nsc/Header";
import HeaderJSC from "@/components/layout/jsc/Header";

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const HeaderWrapper = ({ menuData, tagsData, user }: any) => {
  return websiteId === "nsc" ?
    <HeaderNSC menuData={menuData} tagsData={tagsData} user={user} /> :
    <HeaderJSC menuData={menuData} tagsData={tagsData} user={user} />;
};

export default HeaderWrapper;
