const X = ({ color = "#171C60", className }: { color?: string; className?: string }) => {
  return (
    <svg
      className={className}
      width="69"
      height="63"
      viewBox="0 0 69 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 0.25H0.125L25.9402 34.6703L1.53109 62.7497H9.81244L29.7759 39.7844L47 62.75H68.875L41.9741 26.8822L65.1253 0.25H56.8441L38.1384 21.7681L22 0.25ZM50.125 56.5L12.625 6.5H18.875L56.375 56.5H50.125Z"
        fill={color}
      />
    </svg>
  );
};

export default X;
