import Link from "next/link";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import ChevronFill from "@/components/icons/ChevronFill";
import { cn } from "@/lib/utils";
import { ButtonLink } from "@/components/ui/button";

const websiteId = process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc" || "nsc";

const MobileMenu = ({ open, menuData }: { open: boolean; menuData: any }) => {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [open]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
      animate={{ opacity: open ? 1 : 0 }}
      style={{ pointerEvents: open ? "all" : "none" }}
      className={cn(
        "z-60 absolute h-auto max-h-[calc(100vh-113px)] w-full overflow-auto bg-nsc-dark-blue pb-4 pt-9 lg:hidden",
        websiteId === "jsc" && "bg-white"
      )}
    >
      <div className="container">
        <ul
          className={cn(
            "flex list-none flex-col gap-8 pl-0 text-white",
            websiteId === "jsc" && "text-black"
          )}
        >
          {menuData?.menu?.items.map((item: any) => {
            return item.type === "wrapper_element" ? (
              <NestedMenuItem key={item.id} item={item} />
            ) : (
              <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase">
                <Link href={item.url}>{item.title}</Link>
              </li>
            );
          })}

          <li className="leading-0 mt-0 font-oswald text-base font-bold uppercase">
            <ButtonLink variant="default" size="default" href="/lid-worden">
              Lid worden
            </ButtonLink>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

const NestedMenuItem = ({ item }: { item: any }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className="leading-0 font-oswald text-base font-bold uppercase">
      <button
        onClick={() => setOpen(!open)}
        className={cn(
          "flex items-center gap-2 uppercase text-white",
          websiteId === "jsc" && "text-black"
        )}
      >
        {item.title}
        <ChevronFill
          className={cn("ml-2 transition-transform", open ? "!rotate-0" : "!rotate-180")}
          color="white"
          direction="down"
        />
      </button>
      {open && (
        <ul className="flex flex-col gap-6 py-10 pl-10">
          {item.nestedItems.map((item: any) => (
            <li key={item.id} className="leading-0 font-oswald text-base font-bold uppercase">
              <Link href={item.url}>{item.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default MobileMenu;
