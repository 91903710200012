const Youtube = ({ color = "#171C60", className }: { color?: string; className?: string }) => {
  return (
    <svg
      className={className}
      width="67"
      height="54"
      viewBox="0 0 67 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.8563 10.3162C58.6023 9.32333 57.884 8.5742 57.0613 8.3431C55.5996 7.93556 48.5033 7.00016 33.5033 7.00016C18.5033 7.00016 11.4084 7.93593 9.94082 8.34426C9.12585 8.57093 8.40765 9.31993 8.15032 10.3162C7.78766 11.7309 6.83659 17.6533 6.83659 27.0002C6.83659 36.3468 7.78765 42.2695 8.15132 43.6882C8.40419 44.6772 9.12252 45.4262 9.94169 45.6562C11.4084 46.0645 18.5033 47.0002 33.5033 47.0002C48.5033 47.0002 55.5997 46.0648 57.0657 45.6562C57.8806 45.4295 58.599 44.6805 58.8563 43.6842C59.219 42.2695 60.17 36.3335 60.17 27.0002C60.17 17.6668 59.219 11.7309 58.8563 10.3162ZM65.314 8.66076C66.8366 14.6002 66.8366 27.0002 66.8366 27.0002C66.8366 27.0002 66.8366 39.4002 65.314 45.3395C64.4657 48.6245 61.99 51.2062 58.852 52.0788C53.157 53.6668 33.5033 53.6668 33.5033 53.6668C33.5033 53.6668 13.8612 53.6668 8.15455 52.0788C5.00485 51.1942 2.52929 48.6122 1.69246 45.3395C0.169922 39.4002 0.169922 27.0002 0.169922 27.0002C0.169922 27.0002 0.169922 14.6002 1.69246 8.66076C2.54089 5.37593 5.01649 2.7941 8.15455 1.92136C13.8612 0.333496 33.5033 0.333496 33.5033 0.333496C33.5033 0.333496 53.157 0.333496 58.852 1.92136C62.0016 2.80623 64.4773 5.38803 65.314 8.66076ZM26.8367 38.6668V15.3335L46.8366 27.0002L26.8367 38.6668Z"
        fill={color}
      />
    </svg>
  );
};

export default Youtube;
