import { MotionConfig, motion } from "framer-motion";

import { Button } from "./button";
import { cn } from "@/lib/utils";

const websiteId = process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc";

const Hamburger = ({ open, setOpen }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return (
    <MotionConfig
      transition={{
        duration: 0.3,
        ease: [0.16, 1, 0.3, 1],
      }}
    >
      <Button
        aria-label={open ? "Menu sluiten" : "Menu openen"}
        onClick={() => setOpen(!open)}
        variant="ghost"
        className="ml-auto flex h-auto w-fit max-w-[65px] flex-col gap-1.5 py-0 lg:hidden"
      >
        <motion.span
          animate={{ rotateZ: open ? -45 : 0, y: open ? 9 : 1 }}
          className={cn("h-[3px] w-[37px] bg-white", websiteId === "jsc" && "bg-black")}
        />
        <motion.span
          animate={{ opacity: open ? 0 : 1, x: open ? 1 : 0 }}
          className={cn("h-[3px] w-[37px] bg-white", websiteId === "jsc" && "bg-black")}
        />
        <motion.span
          animate={{ rotateZ: open ? 45 : 0, y: open ? -9 : 0 }}
          className={cn("h-[3px] w-[37px] bg-white", websiteId === "jsc" && "bg-black")}
        />
        <span
          className={cn("font-roboto text-sm normal-case leading-none text-white", websiteId === "jsc" && "text-black")}
        >
          {!open ? "menu" : "sluiten"}
        </span>
      </Button>
    </MotionConfig>
  );
};

export { Hamburger };
