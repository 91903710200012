"use client";

import Link from "next/link";
import { Facebook, Linkedin } from "lucide-react";
import X from "@/components/icons/X";
import Instagram from "@/components/icons/Instagram";
import Youtube from "@/components/icons/Youtube";
import { Button, ButtonLink } from "@/components/ui/button";
import { cn, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import { useStore } from "@/store";
import Arrow from "@/components/icons/Arrow";
import Image from "next/image";

const FooterNSC = ({ settings }: any) => {
  const setCookiesPreferences = useStore((state) => state.setCookiesPreferences);

  if (!settings?.data?.attributes) return null;

  const menuData = settings.data.attributes.websiteFooter;
  const firstMenu = menuData?.menus[0];
  const secondMenu = menuData?.menus[1];
  const thirdMenu = menuData?.menus[2] || {};

  const socials = settings.data.attributes.Socials;

  const renderSocialIcon = (icon: string) => {
    switch (icon) {
      case "Instagram":
        return <Instagram color={"white"} className={`h-8 w-8`} />;
      case "Youtube":
        return <Youtube color={"white"} className={"h-8 w-8"} />;
      case "Twitter":
        return <X color={"white"} className={"h-8 w-8"} />;
      case "Facebook":
        return <Facebook color={"white"} className={`fill-white h-8 w-8 stroke-none`} />;
      case "LinkedIn":
        return <Linkedin color={"white"} className={`fill-white h-8 w-8 stroke-none`} />;
      default:
        return null;
    }
  };

  return (
    <footer
      className={cn(
        "relative z-[100]  pb-[72px] pt-[72px] md:pb-[60px] md:pt-[100px]",
        "bg-nsc-dark-blue text-white",
      )}>
      <div className="grid-container container">
        <div className="col-span-12 md:col-span-2 mb-[62px] md:mb-0">
          <div
            className={cn(
              "relative",
              "h-[43px] w-[92px]",
            )}
          >
            <Image
              src={strapiMediaUrl(menuData.logo)}
              alt={strapiMediaAlt(menuData.logo)}
              fill
              priority
              sizes="(max-width: 600px) 100vw, 50vw"
              className={cn(
                "object-contain",
              )}
            />
          </div>
        </div>

        <div className="col-span-12 md:col-span-3 mb-8 md:mb-0">
          <nav>
            <ul className="flex flex-col gap-4">
              {firstMenu?.items.map((item: any, index: number) => (
                <li key={item.url}
                    className={`leading-0 text-base font-oswald font-bold uppercase md:block`}>
                  <Link href={item.url}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="col-span-12 md:col-span-3">
          <div className="flex flex-col gap-y-6 md:gap-y-14 justify-between md:gap-14">
            <nav>
              <ul className="mb-4 flex flex-col gap-4 md:mb-0">
                {secondMenu?.items.map((item: any, index: number) => {
                  return (
                    <li key={item.url}
                        className={`leading-0 text-base font-oswald font-bold uppercase`}>
                      <Link href={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <nav>
              <ul className="flex flex-col gap-4">
                {thirdMenu?.items.map((item: any, index: number) => {
                  return (
                    <li key={item.url} className="leading-0 font-oswald text-base font-bold uppercase">
                      <Link href={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>

        <div className="col-span-12 md:col-span-3 mt-[48px] md:mt-0">
          <div className="flex flex-col">
            <div className="flex items-center gap-6">
              {socials.map((social: any) => {
                return (
                  <Link
                    key={social.id}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">{social.title}</span>
                    {renderSocialIcon(social.socialNetwork)}
                  </Link>
                );
              })}
            </div>
            <ButtonLink href="/lid-worden" variant="default" size="default" className="mt-11 w-full" fullWidth>
              Lid worden
            </ButtonLink>

            <div className="mt-16">
              <p
                className="whitespace-pre font-roboto text-pretty font-light leading-7"
                dangerouslySetInnerHTML={{ __html: menuData?.information }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid-container container mt-12 md:mt-24">
        <div className="col-span-12 md:col-span-3 md:col-start-3">
          <ButtonLink arrowColor="white" href="/privacy-statement">
            Bekijk onze Privacy statement
          </ButtonLink>
        </div>
        <div className="col-span-12 md:col-span-3">
          <ButtonLink arrowColor="white" href="/anbi">
            Bekijk onze ANBI-status
          </ButtonLink>
        </div>
        <div className="col-span-12 md:col-span-3">
          <Button variant="link" className="p-0 h-full group" onClick={() => setCookiesPreferences(true)}>
            Cookie voorkeuren
            <Arrow color="white" className="ml-3 min-w-fit transition-transform group-hover:translate-x-1" />
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default FooterNSC;
