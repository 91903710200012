"use client";

import React, { useEffect, useState } from "react";

const GridIndicator = () => {
  const [showGrid, setShowGrid] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "g") {
        setShowGrid(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "g") {
        setShowGrid(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (process.env.NODE_ENV === "production") return null;

  return (
    <div
      style={{
        display: showGrid ? "block" : "none",
      }}
      className="fixed left-0 top-0 w-full z-50 h-full"
    >
      <div className="grid-container container pointer-events-none h-full w-full">
        {Array(12)
          .fill(0)
          .map((_, i) => (
            <div key={i} className="col-span-1 bg-[red] opacity-10" />
          ))}
      </div>
    </div>
  );
};

export default GridIndicator;
