const ChevronFill = ({
  color = "#171C60",
  direction = "right",
  className,
}: {
  color?: string;
  direction?: "left" | "right" | "up" | "down";
  className?: string;
}) => {
  const getTransform = (direction: "left" | "right" | "up" | "down") => {
    if (direction === "left") return "rotate(-90deg)";
    if (direction === "up") return "rotate(0deg)";
    if (direction === "down") return "rotate(180deg)";
    if (direction === "right") return "rotate(90deg)";
    return "";
  };

  return (
    <svg
      style={{
        transform: getTransform(direction),
      }}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M5.62917 0L11.2583 9.75H0L5.62917 0Z" fill={color} />
    </svg>
  );
};

export default ChevronFill;
