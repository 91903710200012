import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptcha"] */ "/workspace/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaContext","ReCaptchaProvider","useReCaptchaContext"] */ "/workspace/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReCaptcha"] */ "/workspace/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["withReCaptcha"] */ "/workspace/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"500\",\"700\",\"900\"],\"variable\":\"--font-roboto\",\"subsets\":[\"latin\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Oswald\",\"arguments\":[{\"weight\":\"700\",\"variable\":\"--font-oswald\",\"subsets\":[\"latin\"]}],\"variableName\":\"oswald\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"700\"],\"variable\":\"--font-lato\",\"subsets\":[\"latin\"]}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/general/GridIndicator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/CookiesWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/FooterWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/layout/HeaderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/globals.scss");
