"use client";

import { useEffect } from "react";

import FooterNSC from "@/components/layout/nsc/Footer";
import FooterJSC from "@/components/layout/jsc/Footer";
import { useStore } from "@/store";

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const FooterWrapper = ({ settings }: any) => {
  const { setSettings } = useStore();

  useEffect(() => {
    setSettings(settings.data.attributes);
  }, [settings]);

  if (!settings?.data?.attributes) return null;

  return websiteId === "nsc" ? <FooterNSC settings={settings} /> : <FooterJSC settings={settings} />;
};

export default FooterWrapper;
