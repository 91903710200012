const Search = ({ color = "#171C60", className }: { color?: string; className?: string }) => {
  return (
    <svg
      className={className}
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="4" stroke={color} strokeWidth="2" />
      <line x1="8.8" y1="9.4" x2="11.8" y2="13.4" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default Search;
